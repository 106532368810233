import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { IOrder } from './order';
import { IUser } from './user';

export type ApiIndividualPayment = ApiIndividualResponse<IPayment>;
export type ApiPaginatedPayments = ApiPaginatedResponse<IPayment>;
export type ApiPayPayments = ApiIndividualResponse<IPayPayments>;

export enum PaymentInclude {
  Course = 'course',
  Order = 'order',
  User = 'user',
}

export enum PaymentType {
  RepaymentToEP = 'REPAYMENT_TO_EP',
  FixPayInstalment = 'FIXPAY_INSTALMENT',
  Upfront = 'UPFRONT',
  LearningBudget = 'LEARNING_BUDGET',
}

export enum PaymentStatus {
  Authorized = 'AUTHORIZED',
  Pending = 'PENDING',
  Paid = 'PAID',
  Failed = 'FAILED',
}

export enum PaymentMethod {
  CreditCard = 'CREDIT_CARD',
  BankTransfer = 'BANK_TRANSFER',
}

export enum PaymentResource {
  LearningBudget = 'learning_budget',
  TaxBenefits = 'tax_benefits',
  StudentRepayment = 'student_repayment',
}

export interface IPayPayments {
  paymentCodeReference: string;
  redirectUrl: string;
}

export interface IPayment {
  _id?: string;
  user: IUser | string;
  totalAmount: number;
  currency: string;
  agreementFeeAmount: number;
  status: PaymentStatus;
  externalPaymentId?: string;
  paymentMethod: PaymentMethod;
  automaticPayout: boolean;
  transferred: boolean;
  paymentType: PaymentType;
  paymentResource: PaymentResource;
  scheduledTo?: Date;
  payer?: string;
  order?: IOrder;
  createdAt: Date;
  updatedAt: Date;
}
