import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { useNavigate } from '@tanstack/react-router';
import { useGetConnectStripeAccountLinks } from '../hooks/useGetStripeLinksEducationProvider';
import { FlowModes } from '../types';

type StripeBannerProps = React.HTMLAttributes<HTMLDivElement>;

export function StripeBanner(props: StripeBannerProps) {
  const navigate = useNavigate();

  const { educationProvider, isLoading: isLoadingUserContext } =
    useCurrentUserContext() ?? {};
  const { onboardingState } = educationProvider ?? {};

  const shouldConnectStripeAccount = !onboardingState?.hasActivePaymentProvider;

  const {
    data: { data: { connectAccountLink = '' } = {} } = {},
    isLoading: isLoadingStripeLinks,
  } = useGetConnectStripeAccountLinks(educationProvider?._id, {
    enabled: !!educationProvider?._id && shouldConnectStripeAccount,
  });

  const redirectToStripeConnect = () => {
    return navigate({
      to: '/stripe',
      search: {
        mode: FlowModes.CONNECT_ACCOUNT,
        id: educationProvider?._id ?? '',
      },
    });
  };

  if (isLoadingUserContext || isLoadingStripeLinks) {
    return null;
  }

  return shouldConnectStripeAccount && connectAccountLink ? (
    <div
      {...props}
      className={`flex flex-col @md:flex-row @md:items-center @md:justify-between gap-3 border rounded-xl px-3 py-6 @md:px-4 overflow-hidden bg-mydra-violet/40 ${props.className ?? ''} `}
    >
      <div className="flex flex-col gap-3">
        <div className="flex gap-1 items-center">
          <img
            src="/images/stripe-logo.png"
            alt="Stripe logo"
            className="w-8 h-8"
          />
          <h1 className="text-sm @md:text-base font-medium @md:font-semibold">
            Stripe account
          </h1>
        </div>
        <p className="text-xs @md:text-sm @md:font-medium">
          Set up your stripe account to start your payments onboarding.
        </p>
      </div>
      <Button
        className="@md:ml-auto"
        primary
        small
        onClick={redirectToStripeConnect}
        aria-label="Connect Stripe account"
      >
        <span className="@md:hidden">Let's go</span>
        <span className="hidden @md:block">Connect Stripe account</span>
      </Button>
    </div>
  ) : null;
}
