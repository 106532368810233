import { Button } from '@/modules/common/components/Button';
import { useNavigate } from '@tanstack/react-router';

export const ContractExplanationStep4 = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-6">
      <p className="text-lg text-text">
        Please review this information carefully before signing.
      </p>

      <div className="flex flex-col gap-6 text-black">
        <h3 className="text-3xl font-semibold">
          Compliance, Claims, and Termination
        </h3>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Legal Compliance</h4>
          <p className="text-lg">
            You must adhere to all relevant laws, and, if requested, provide
            compliance documentation to StudentFinance.
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Handling Claims</h4>
          <p className="text-lg">
            You'll address any claims related to your programs, while
            StudentFinance will handle claims related to payment options like
            FixPay.
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Termination</h4>
          <p className="text-lg">
            This contract renews annually, but either party may terminate with
            30 days' notice. StudentFinance may also terminate immediately in
            cases of contract breach, such as fraud.
          </p>
        </div>
      </div>

      <div className="mt-12 ml-auto">
        <Button
          primary
          onClick={() => {
            navigate({
              search: {
                step: 5,
              },
            });
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
