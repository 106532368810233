import { getValidationNames } from '@/modules/common/lib/application-review';
import { UserRole } from '@/types/user';
import { EducationProviderReviewModal } from './EducationProviderReviewModal';
import { PaymentFeaturesReviewModal } from './PaymentFeaturesReviewModal';
import { ReviewModalProps } from './ReviewModal.types';

export function ReviewModal({
  applicationsToReview,
  userRoles,
  initialReview,
  onClose,
  ...props
}: ReviewModalProps) {
  const validationsToReview = getValidationNames(
    applicationsToReview,
    userRoles,
  );

  return userRoles.includes(UserRole.EducationProvider) ? (
    <EducationProviderReviewModal
      applicationsToReview={applicationsToReview}
      userRoles={userRoles}
      onClose={onClose}
      validationsToReview={validationsToReview}
      {...props}
    />
  ) : (
    <PaymentFeaturesReviewModal
      applicationsToReview={applicationsToReview}
      userRoles={userRoles}
      initialReview={initialReview}
      onClose={onClose}
      validationsToReview={validationsToReview}
      {...props}
    />
  );
}
