import { ContractService } from '@/services/contracts';
import { ApiErrorResponse } from '@/types/api';
import { IContractPostPayload, PostContractResponse } from '@/types/contract';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useContractMutation() {
  const mutationKey = [ContractService.GET_PATH];
  const queryClient = useQueryClient();

  return useMutation<
    PostContractResponse,
    ApiErrorResponse,
    IContractPostPayload
  >({
    mutationKey: mutationKey,
    mutationFn: ContractService.postContract,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ContractService.GET_PATH],
        exact: false,
      });
    },
  });
}
