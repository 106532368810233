import { USER_ROLES } from '@/constants';
import { getUseCurrentUserOptions } from '@/modules/common/hooks/useUser';
import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { CompanySettingsPage } from '@/modules/settings/pages/CompanySettingsPage';
import { EducationProviderSettingsPage } from '@/modules/settings/pages/EducationProviderSettingsPage';
import { UserInclude } from '@/types/user';
import { createFileRoute, useRouteContext } from '@tanstack/react-router';

export const Route = createFileRoute('/_admin/settings/')({
  beforeLoad: async ({ context: { queryClient } }) => {
    const { data: user } = await queryClient.ensureQueryData(
      getUseCurrentUserOptions([
        UserInclude.EducationProvider,
        UserInclude.Company,
        UserInclude.TaxBenefitProviders,
      ]),
    );

    return {
      user,
    };
  },
  component: function SettingsPageComponentSwitcher() {
    const { user } = useRouteContext({ from: Route.id });

    if (!user) {
      return null;
    }

    const isEducationProviderUser = user?.roles.includes(
      USER_ROLES.EDUCATION_PROVIDER,
    );

    return (
      <AdminLayout hideBanners>
        {isEducationProviderUser ? (
          <EducationProviderSettingsPage />
        ) : (
          <CompanySettingsPage />
        )}
      </AdminLayout>
    );
  },
});
