import {
  ApplicationValidationReviewer,
  ApplicationValidationStatus,
  ApplicationValidationType,
  IApplicationValidation,
  IApplicationWithOrderAndCourse,
} from '@/types/application';
import { UserRole } from '@/types/user';

export const getReviewerPendingValidations = (
  validations: IApplicationValidation[],
  reviewer: ApplicationValidationReviewer,
) => {
  return validations.filter(validation =>
    validation.reviews.some(
      review =>
        review.reviewer === reviewer &&
        review.status === ApplicationValidationStatus.Pending,
    ),
  );
};

export const getValidationNames = (
  applications: IApplicationWithOrderAndCourse[],
  userRoles: UserRole[],
) => {
  const validationNames = applications.reduce((acc, application) => {
    if (userRoles.includes(UserRole.Employer)) {
      getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.Employer,
      ).forEach(validation => acc.add(validation.name));
    }

    if (userRoles.includes(UserRole.TaxBenefitsProvider)) {
      getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.FlexibleRemunerationProvider,
      ).forEach(validation => acc.add(validation.name));
    }

    if (userRoles.includes(UserRole.EducationProvider)) {
      getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.EducationProvider,
      ).forEach(validation => acc.add(validation.name));
    }

    return acc;
  }, new Set<ApplicationValidationType>());

  return Array.from(validationNames);
};
