import { EXTERNAL_ROUTES } from '@/constants';
import { Button } from '@/modules/common/components/Button';
import { Tag } from '@/modules/common/components/Tag';
import { getReviewerPendingValidations } from '@/modules/common/lib/application-review';
import { formatCurrencyValue } from '@/modules/common/lib/formatters';
import { roundHalfToEven } from '@/modules/common/lib/moneyHandler';
import { stringToColor } from '@/modules/common/lib/string';
import {
  ApplicationStatus,
  ApplicationTerm,
  ApplicationValidationReviewer,
  ApplicationValidationType,
  IApplicationWithOrderAndCourse,
} from '@/types/application';
import { ICompany } from '@/types/company';
import { ICourse } from '@/types/course';
import { IUser, UserRole } from '@/types/user';
import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import pluralize from 'pluralize';
import { ApplicationStatus as ApplicationStatusTag } from '../ApplicationStatus';
import { ReviewModalState } from './components/ReviewModal/ReviewModal.types';
import { TableHeader } from './components/TableHeader';

const columnHelper = createColumnHelper<IApplicationWithOrderAndCourse>();

const getPrice = (application: IApplicationWithOrderAndCourse) => {
  const cohort = (application.course as ICourse).cohorts?.find(
    cohort => cohort._id === application.cohort,
  );

  if (cohort?.cost?.amount) {
    return formatCurrencyValue(
      cohort.cost.amount.toString(),
      cohort.cost.currency,
    );
  }

  return '-';
};

const showReviewAction = (
  application: IApplicationWithOrderAndCourse,
  userRoles: string[],
) => {
  const { statuses = [], validations = [] } = application;

  if (statuses[statuses.length - 1] !== ApplicationStatus.PendingValidation) {
    return false;
  }

  if (userRoles.includes(UserRole.TaxBenefitsProvider)) {
    const taxBenefitsProviderValidations = getReviewerPendingValidations(
      validations,
      ApplicationValidationReviewer.FlexibleRemunerationProvider,
    );

    console.log(taxBenefitsProviderValidations);

    return !!taxBenefitsProviderValidations?.length;
  }

  if (userRoles.includes(UserRole.EducationProvider)) {
    const educationProviderValidations = getReviewerPendingValidations(
      validations,
      ApplicationValidationReviewer.EducationProvider,
    );

    return !!educationProviderValidations?.length;
  }

  const employerValidations = getReviewerPendingValidations(
    validations,
    ApplicationValidationReviewer.Employer,
  );
  return !!employerValidations?.length;
};

const getApplicationTypes = (app: IApplicationWithOrderAndCourse) => {
  const types = [];

  if (app.order.learningBudgetAmount?.amount) {
    if (app.order.learningBudgetAmount.amount === app.order.totalAmount) {
      types.push('Learning Budget');
    } else {
      types.push('Learning Budget', app.applicationTerm);
    }
  } else {
    types.push(app.applicationTerm);
  }

  if (app.flexibleRemuneration) {
    types.push('Retribucion Flexible');
  }

  return types.map(t => (
    <Tag
      className="whitespace-nowrap"
      // style={{ backgroundColor: stringToColor(t) }}
      text={t}
    />
  ));
};

type GetListApplicationsTableColumnsArgs = {
  onApplicationReview: (application: IApplicationWithOrderAndCourse) => void;
  userRoles: string[];
};

export function getListApplicationsTableColumns({
  onApplicationReview,
  userRoles,
}: GetListApplicationsTableColumnsArgs) {
  return [
    columnHelper.display({
      id: 'checkbox',
      header: () => null,
      cell: ({ row }) => {
        const isSelected = row.getIsSelected();
        const isEducationProvider = userRoles.includes(
          UserRole.EducationProvider,
        );

        const isDisabled =
          isEducationProvider || !showReviewAction(row.original, userRoles);

        return (
          <div className="flex justify-center flex-1 p-4 align-center">
            <input
              type="checkbox"
              className="disabled:cursor-not-allowed"
              checked={isSelected}
              disabled={isDisabled}
              title={
                isDisabled
                  ? 'Education provider reviews are done on a one-by-one basis'
                  : ''
              }
              onChange={() => row.toggleSelected()}
            />
          </div>
        );
      },
    }),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider)
      ? [
          columnHelper.accessor('user.company.name', {
            header: () => <TableHeader>Company</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {((original.user as IUser)?.company as ICompany)?.name ??
                      ''}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor('user', {
      header: () => <TableHeader>Employee</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <div className="text-sm font-medium">
                <Link
                  to={`/employees/${original.user._id}`}
                >{`${(original.user as IUser)?.firstName} ${(original.user as IUser)?.lastName}`}</Link>
              </div>
              <div className="text-xxs text-text">
                {(original.user as IUser).email}
              </div>
            </div>
          </div>
        );
      },
    }),
    ...(userRoles.includes(UserRole.EducationProvider)
      ? [
          columnHelper.accessor('user.profile.currentRole', {
            header: () => <TableHeader>Job Role</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div className="text-xs">
                  {original.user.profile?.currentRole ?? '(not informed)'}
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor('course.name', {
      header: () => <TableHeader>Course</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div>
            <p className="text-xs font-semibold text-mydra-black">
              <a
                className="cursor-pointer hover:underline"
                onClick={() => {
                  window.open(
                    EXTERNAL_ROUTES.MARKETPLACE_COURSE_URL.replace(
                      ':id',
                      original.course._id!,
                    ),
                    '_blank',
                    'noopener noreferrer',
                  );
                }}
              >
                {original.course?.name}
              </a>
            </p>
            <p className="text-xs text-mydra-black">
              {original.course?.educationProvider?.name}
            </p>
          </div>
        );
      },
    }),
    ...(userRoles.includes(UserRole.EducationProvider)
      ? [
          columnHelper.accessor('course.skills', {
            header: () => <TableHeader>Skills</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div className="flex flex-wrap gap-1 text-xs">
                  {(original.course?.skills ?? []).map(skillName => (
                    <Tag
                      className="!rounded-full bg-mydra-violet"
                      key={skillName}
                      text={skillName}
                    />
                  ))}
                </div>
              );
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider) ||
    userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('course.cohorts.cost', {
            header: () => <TableHeader>Price</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {getPrice(original)}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('learningBudget', {
            header: () => <TableHeader>Learning Budget</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {original.learningBudget?.amount
                      ? formatCurrencyValue(
                          original.learningBudget.amount.toString(),
                          original.learningBudget.currency,
                        )
                      : '-'}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider) ||
    userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('applicationTerm', {
            header: () => <TableHeader>Type</TableHeader>,
            cell: ({ row: { original } }) => {
              if (!original.order) {
                return (
                  <Tag
                    className={`whitespace-nowrap bg-${stringToColor(original.applicationTerm)}`}
                    text={original.applicationTerm.toLowerCase()}
                  />
                );
              }

              return <div>{getApplicationTypes(original)}</div>;
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider) ||
    userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('order.userPaymentAmount.amount', {
            header: () => <TableHeader>Payment details</TableHeader>,
            cell: ({ row: { original } }) => {
              const totalAmount = original.order.userPaymentAmount.amount;
              const numberOfInstalments =
                original.applicationData?.contractChoices
                  ?.fixedNumberOfInstalments ?? 1;
              const amountPerInstalment = roundHalfToEven(
                totalAmount / numberOfInstalments,
              );

              return (
                <div className="flex flex-col">
                  <p>
                    {formatCurrencyValue(
                      totalAmount.toString(),
                      original.order.userPaymentAmount.currency,
                      { maximumFractionDigits: 2 },
                    )}
                  </p>
                  {original.applicationTerm === ApplicationTerm.Isa && (
                    <p className="text-xs">
                      {numberOfInstalments}{' '}
                      {pluralize('installment', numberOfInstalments)} of{' '}
                      {formatCurrencyValue(
                        amountPerInstalment.toString(),
                        original.order.userPaymentAmount.currency,
                        { maximumFractionDigits: 2 },
                      )}
                    </p>
                  )}
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor('statuses', {
      header: () => <TableHeader>Status</TableHeader>,
      cell: ({ row: { original } }) => (
        <ApplicationStatusTag
          status={original.statuses![original.statuses!.length - 1]}
        />
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <TableHeader className="text-right">Actions</TableHeader>,
      cell: ({ row: { original } }) => {
        const isReviewActionVisible = showReviewAction(original, userRoles);

        return (
          <div
            className={`${isReviewActionVisible ? 'flex gap-2 ml-auto' : 'w-full'}`}
          >
            <div className={isReviewActionVisible ? 'ml-auto' : 'text-center'}>
              {isReviewActionVisible ? (
                <Button
                  small
                  onClick={() => {
                    onApplicationReview(original);
                  }}
                >
                  Review
                </Button>
              ) : (
                <span className="text-center">&mdash;</span>
              )}
            </div>
          </div>
        );
      },
    }),
  ];
}

export const formatReviewCalls = (
  applications: IApplicationWithOrderAndCourse[],
  review: Partial<ReviewModalState>,
  userRoles: UserRole[],
) => {
  return applications.flatMap(application => {
    const validations = [
      ...getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.Employer,
      ),
      ...getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.FlexibleRemunerationProvider,
      ),
      ...getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.EducationProvider,
      ),
    ].filter(validation => {
      if (userRoles.includes(UserRole.Employer)) {
        return validation.reviews.some(
          review => review.reviewer === ApplicationValidationReviewer.Employer,
        );
      }

      if (userRoles.includes(UserRole.TaxBenefitsProvider)) {
        return validation.reviews.some(
          review =>
            review.reviewer ===
            ApplicationValidationReviewer.FlexibleRemunerationProvider,
        );
      }

      if (userRoles.includes(UserRole.EducationProvider)) {
        return validation.reviews.some(
          review =>
            review.reviewer === ApplicationValidationReviewer.EducationProvider,
        );
      }

      return false;
    });

    const reviewCalls = [];

    if (
      validations.some(
        validation =>
          validation.name === ApplicationValidationType.FlexibleRemuneration,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: review[ApplicationValidationType.FlexibleRemuneration],
          validationType: ApplicationValidationType.FlexibleRemuneration,
        },
      });
    }

    if (
      validations.some(
        validation =>
          validation.name === ApplicationValidationType.LearningBudget,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: review[ApplicationValidationType.LearningBudget],
          validationType: ApplicationValidationType.LearningBudget,
        },
      });
    }

    if (
      validations.some(
        validation => validation.name === ApplicationValidationType.Skills,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: review[ApplicationValidationType.Skills],
          validationType: ApplicationValidationType.Skills,
        },
      });
    }

    if (
      validations.some(
        validation => validation.name === ApplicationValidationType.Capacity,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: review[ApplicationValidationType.Capacity],
          validationType: ApplicationValidationType.Capacity,
        },
      });
    }

    if (
      validations.some(
        validation =>
          validation.name === ApplicationValidationType.AdmissionProcess,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: review[ApplicationValidationType.AdmissionProcess],
          validationType: ApplicationValidationType.AdmissionProcess,
        },
      });
    }

    return reviewCalls;
  });
};
