import { ApiService } from '@/services/api';
import { ApiIndividualResponse } from '@/types/api';
import {
  AuthConfirmResetPayload,
  AuthLoginPayload,
  AuthResetPayload,
  AuthSession,
} from '@/types/auth';

export class AuthService extends ApiService {
  static PATH = '/auth';

  static async loginUser(
    payload: AuthLoginPayload,
  ): Promise<ApiIndividualResponse<AuthSession>> {
    return ApiService.post(`${AuthService.PATH}/login`, payload);
  }

  static async logoutUser(): Promise<AuthSession> {
    return ApiService.post(`${AuthService.PATH}/logout`, {});
  }

  static async requestUserPasswordReset(
    payload: AuthResetPayload,
  ): Promise<AuthSession> {
    return ApiService.post(`${AuthService.PATH}/reset-password`, payload);
  }

  static async resetUserPassword(
    payload: AuthConfirmResetPayload,
  ): Promise<AuthSession> {
    return ApiService.put(`${AuthService.PATH}/reset-password`, payload);
  }
}
