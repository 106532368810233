import { DocumentsService } from '@/services/document';
import { ApiErrorResponse } from '@/types/api';
import {
  ApiPaginatedDocuments,
  IDocument,
  IDocumentFilter,
} from '@/types/document';
import { PaginationSearchParams } from '@/types/pagination';
import { SortingSearchParams } from '@/types/sorting';
import { UseQueryOptions } from '@tanstack/react-query';

export const UseDocumentsQueryOptions = (
  filters: IDocumentFilter,
  pagination?: PaginationSearchParams,
  sorting?: SortingSearchParams<IDocument>,
  options?: Omit<
    UseQueryOptions<ApiPaginatedDocuments, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) => ({
  queryKey: [DocumentsService.PATH, filters, pagination, sorting],
  queryFn: async () =>
    await DocumentsService.getDocuments(filters, pagination, sorting),
  ...options,
});
