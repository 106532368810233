import { Button } from '@/modules/common/components/Button';

export const HelpBanner = () => {
  return (
    <div className="flex items-center justify-between px-6 py-4 overflow-hidden bg-center bg-no-repeat bg-cover rounded-lg bg-help-banner">
      <p className="font-semibold text-white">
        Need more help? Just send us a message — we're here to assist!
      </p>
      <a href="mailto:support@mydra.io" target="_blank">
        <Button
          primary
          className="!bg-black hover:!bg-gray-900 text-white px-6 font-medium"
        >
          Get help!
        </Button>
      </a>
    </div>
  );
};
