import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { useInviteEmployees } from '@/modules/common/hooks/useEmployer';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { useInvitationsMutation } from '@/modules/employees/hooks/useInvitationsMutation';
import { useGetTeams } from '@/modules/employees/hooks/useTeams';
import { ApiErrorResponse } from '@/types/api';
import { ITeam } from '@/types/team';
import { ModalProps } from '@mui/base';
import { CloudUpload, Refresh } from '@mui/icons-material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InviteUserForm } from '../InviteUserForm/InviteUserForm';

type InviteEmployeesDialog = Omit<ModalProps, 'children'> & {
  company: string;
  teams: ITeam[];
  onSuccess: () => void;
};

export function InviteEmployeesDialog({
  onSuccess,
  ...props
}: InviteEmployeesDialog) {
  const { showNotification } = useNotificationContext();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: { file: null },
  });

  const [selectedFileName, setSelectedFileName] = useState('Choose csv file');

  const { mutateAsync: inviteEmployees, isPending: isUploadingFile } =
    useInviteEmployees();

  const { data: { data: teams = [] } = {} } = useGetTeams(
    props.company! as string,
  );

  const { mutateAsync: sendInvitation } = useInvitationsMutation({
    onSuccess: () => {
      showNotification(
        'Invitation sent successfully',
        NotificationStyle.SUCCESS,
      );
    },
    onError: () => {
      showNotification('Failed to send invitation', NotificationStyle.ERROR);
    },
  });

  const handleInviteEmployeesSubmit = async (data: {
    file: FileList | null;
  }) => {
    if (!data.file) return;

    const file = data.file[0];

    const formData = new FormData();
    formData.append('file', file);

    try {
      await inviteEmployees({
        id: props.company,
        file: formData,
      });
      onSuccess();
      showNotification(`File uploaded successfuly`, NotificationStyle.SUCCESS);
    } catch (error) {
      if ((error as ApiErrorResponse).statusCode === 400) {
        return setError('root.serverError', {
          type: 'manual',
          message:
            (error as ApiErrorResponse).message ??
            'An error occurred. Please try again later.',
        });
      }
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleCancel = () => {
    props.onClose?.({}, 'backdropClick');
  };

  return (
    <Modal
      {...props}
      title={`Invite Employees`}
      subTitle={
        'Invite employees individually or in batch by uploading a CSV file'
      }
      onClose={(...args) => {
        reset();
        props.onClose?.(...args);
      }}
      className="max-w-xl mx-auto p-8 pb-3"
    >
      <>
        <InviteUserForm teams={teams} onSubmit={sendInvitation} />
        <hr className="mt-6 mb-4 hr-text" data-content="OR" />
        <form
          onSubmit={handleSubmit(handleInviteEmployeesSubmit)}
          className="flex flex-col justify-end gap-3 p-2"
        >
          {errors.root?.serverError?.message && (
            <ul className="text-red-500 list-disc">
              {errors.root.serverError.message
                ?.split(';')
                .map((error, index) => (
                  <li key={index}>
                    {error}
                    <br />
                  </li>
                ))}
            </ul>
          )}
          <div className="flex flex-col gap-6">
            <Controller
              control={control}
              name="file"
              render={({ field: { onChange, value, ...field } }) => (
                <>
                  <p>
                    Alternatively can upload a CSV file with your employees info
                    and have them invited all at once.
                  </p>
                  <p>
                    Here's an example of the CSV file format we're expecting:
                  </p>
                  <p className="text-gray-500 pl-4 pr-4 pt-2 pb-2 border rounded text-sm">
                    <code className="block">name,role,team</code>
                    <code className="block">
                      user1@mycompany.com,admin,Engineering
                    </code>
                    <code className="block">
                      user2@mycompany.com,user,Marketing
                    </code>
                  </p>
                  <hr />
                  <label
                    htmlFor="csv-file-upload"
                    className="px-3 py-2 border rounded-lg cursor-pointer shrink-0 active:bg-mydra-gray text-gray-500 flex items-center justify-between gap-2"
                  >
                    {isUploadingFile ? (
                      <Refresh className="animate-spin" />
                    ) : (
                      <>
                        <span>{selectedFileName}</span>
                        <CloudUpload className="w-5 h-5 text-gray-500" />
                      </>
                    )}
                  </label>
                  <input
                    {...field}
                    id="csv-file-upload"
                    type="file"
                    accept=".csv"
                    onChange={e => {
                      onChange(e.target.files);
                      setSelectedFileName(
                        e.target.files?.[0]?.name || 'Choose csv file',
                      );
                    }}
                    className="invisible w-0 h-0"
                    disabled={isUploadingFile}
                  />
                </>
              )}
            />
            {errors.file?.message && (
              <p className="text-sm text-red-500">{errors.file.message}</p>
            )}
          </div>
          <hr className="absolute left-0 right-0 mb-14" />
          <div className="flex justify-end space-x-4">
            <Button
              small
              type="button"
              onClick={handleCancel}
              className="w-32 mt-6"
            >
              Cancel
            </Button>
            <Button
              primary
              small
              type="submit"
              className="w-32 mt-6"
              isLoading={isUploadingFile}
            >
              Upload
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
}
