import { useMutation, useQuery } from '@tanstack/react-query';

import { UserService } from '@/services/user';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactQueryOptions,
} from '@/types/api';
import {
  ApiIndividualUser,
  ApiPaginatedUser,
  IUser,
  UserInclude,
} from '@/types/user';

export const useUsers = (
  filters: ApiQueryParams = {},
  pagination: ApiPaginationParams = {},
  options?: ApiReactQueryOptions<ApiPaginatedUser>,
) =>
  useQuery<ApiPaginatedUser, ApiErrorResponse>({
    queryKey: [UserService.PATH, filters, pagination],
    queryFn: async () => await UserService.getUsers(filters, pagination),
    ...options,
  });

export const useUser = (
  id: string,
  options?: ApiReactQueryOptions<ApiIndividualUser>,
) =>
  useQuery<ApiIndividualUser, ApiErrorResponse>({
    queryKey: [UserService.PATH, id],
    queryFn: async () => await UserService.getUser(id),
    ...options,
  });

export const getUseCurrentUserOptions = (include?: UserInclude[]) => ({
  queryKey: [UserService.PATH, include],
  queryFn: () => UserService.getCurrentUser(include),
});

export const useCurrentUser = (
  include?: UserInclude[],
  options?: ApiReactQueryOptions<ApiIndividualUser>,
) =>
  useQuery<ApiIndividualUser, ApiErrorResponse>({
    ...getUseCurrentUserOptions(include),
    ...options,
  });

export const useUserCreation = () =>
  useMutation<ApiIndividualUser, ApiErrorResponse, IUser>({
    mutationKey: [UserService.PATH],
    mutationFn: UserService.createUser,
  });

export const useUserUpdate = () =>
  useMutation<ApiIndividualUser, ApiErrorResponse, IUser>({
    mutationKey: [UserService.PATH],
    mutationFn: UserService.updateUser,
  });
