import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { ContractLayout } from '@/modules/onboarding/layouts/ContractLayout';
import { SignContractPage } from '@/modules/onboarding/pages/SignContractPage';
import { createFileRoute } from '@tanstack/react-router';
import * as yup from 'yup';

export type ContractSearchParams = {
  step?: number;
  esio_contract_id?: string;
  esio_signer_id?: string;
};

export const Route = createFileRoute('/_admin/contract/')({
  validateSearch: (search: Record<string, unknown>): ContractSearchParams => ({
    step: yup
      .number()
      .min(1)
      .max(5)
      .optional()
      .default(1)
      .validateSync(search.step),
  }),
  component: () => (
    <ContractLayout>
      <SignContractPage />
    </ContractLayout>
  ),
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 500 }} reset={() => null} />;
  },
});
