import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { getGetConnectStripeAccountLinksQueryOptions } from '@/modules/onboarding/hooks/useGetStripeLinksEducationProvider';
import { StripeAccountPage } from '@/modules/onboarding/pages/StripeAccountPage';
import {
  FlowModes,
  StripeOnboardingSearchParams,
} from '@/modules/onboarding/types';
import { createFileRoute, defer } from '@tanstack/react-router';
import * as yup from 'yup';

export const Route = createFileRoute('/stripe')({
  component: StripeAccountPage,
  loaderDeps: ({ search: { mode, id } }) => ({ mode, id }),
  loader: async ({ context: { queryClient }, deps: { id } }) => {
    return {
      links: defer(
        queryClient.ensureQueryData(
          getGetConnectStripeAccountLinksQueryOptions(id),
        ),
      ),
    };
  },
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 404 }} reset={() => null} />;
  },
  validateSearch: (
    search: Record<string, unknown>,
  ): StripeOnboardingSearchParams => {
    return {
      mode: yup
        .string()
        .required()
        .oneOf(Object.values(FlowModes))
        .validateSync(search.mode),
      id: yup.string().uuid().required().validateSync(search.id),
    };
  },
});
