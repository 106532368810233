import { ApiErrorResponse, ApiIndividualResponse } from '@/types/api';
import { IDocumentWithTemporaryUrl } from '@/types/document';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UseDocumentQueryOptions } from './useDocumentQueryOptions';

export function useDocument(
  documentId: string,
  options?: Omit<
    UseQueryOptions<
      ApiIndividualResponse<IDocumentWithTemporaryUrl>,
      ApiErrorResponse
    >,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(UseDocumentQueryOptions(documentId, options));
}
