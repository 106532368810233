import { yupResolver } from '@hookform/resolvers/yup';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/modules/common/components/Button';
import { Switch } from '@/modules/common/components/Switch';
import { TextField } from '@/modules/common/components/TextField';
import { ITeam } from '@/types/team';
import { InviteType } from '../../types';

export type InviteUserFormValues = {
  email: string;
  role: string;
  team?: string;
  inviteType: InviteType;
};

type InviteUserFormProps = Omit<
  React.FormHTMLAttributes<HTMLFormElement>,
  'onSubmit'
> & {
  teams: ITeam[];
  onSubmit: (data: InviteUserFormValues) => Promise<unknown>;
};

const FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email('Please provide an valid e-mail address')
    .required('Please input your email address'),
  role: yup.string().required().oneOf(['user', 'employer']), // TODO
  team: yup.string().optional(),
  inviteType: yup.string().required().oneOf(Object.values(InviteType)),
});

export function InviteUserForm({
  teams = [],
  onSubmit,
  ...rest
}: InviteUserFormProps) {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<InviteUserFormValues>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      email: '',
      role: 'user',
      team: '',
      inviteType: InviteType.User,
    },
  });

  const handleFormSubmit = async (data: InviteUserFormValues) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      {...rest}
      className="flex flex-col w-full gap-4"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className="flex items-center justify-between w-full gap-4">
        {' '}
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="email"
              errorMessage={errors.email?.message as string}
              name="name"
              id="name"
              className="text-sm flex-1"
              label="Email"
              placeholder="user@company.com"
            />
          )}
        />
        <div className="flex flex-col items-center min-w-[100px] self-end">
          <Controller
            name="team"
            control={control}
            render={({ field }) => (
              <FormControl size="small" sx={{ minWidth: 100 }}>
                <Select
                  {...field}
                  labelId="invitation-select-team"
                  onChange={e => field.onChange(e.target.value)}
                  sx={{
                    borderRadius: '0.75rem',
                    fontSize: '0.9rem',
                    color: '#aaa',
                  }}
                  displayEmpty
                  renderValue={selected => {
                    if (!selected) {
                      return <em>Team</em>;
                    }

                    return selected;
                  }}
                >
                  {teams.length === 0 ? (
                    <MenuItem value="" disabled>
                      No teams available
                    </MenuItem>
                  ) : (
                    teams.map(t => (
                      <MenuItem key={t._id} value={t._id}>
                        {t.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="flex flex-col items-center min-w-[80px]">
          {' '}
          <label htmlFor="role" className="text-sm">
            Admin
          </label>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Switch
                defaultChecked={field.value === 'employer'}
                onChange={e => {
                  field.onChange(e.target.checked ? 'employer' : 'user');
                }}
              />
            )}
          />
        </div>
      </div>
      <Button primary small type="submit" isLoading={isSubmitting}>
        Invite
      </Button>
    </form>
  );
}
