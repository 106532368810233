import { NavBar } from '@/modules/common/components/NavBar';
import { ArrowBack } from '@mui/icons-material';
import { useRouter } from '@tanstack/react-router';
import { ContractStepsNav } from '../components/ContractStepsNav';
import { HelpBanner } from '../components/HelpBanner';

type ContractLayoutProps = React.HTMLAttributes<HTMLDivElement>;

export const ContractLayout = ({ children, ...props }: ContractLayoutProps) => {
  const router = useRouter();

  return (
    <div
      {...props}
      className={`flex relative flex-col w-full ${props.className ?? ''}`}
    >
      <NavBar
        className="sticky top-0 z-10"
        afterLogo={<span>Contract</span>}
        showUserMenu
      />
      <div className="flex overflow-hidden relative z-0 flex-1 gap-5 mx-auto my-8 w-full max-w-6xl">
        <div className="flex flex-col flex-1 gap-12">
          <main className="overflow-auto flex-1 rounded-lg border h-min">
            <div className="p-6">
              <button
                onClick={() => router.history.back()}
                className="flex justify-center items-center mb-6 w-12 h-12 rounded-lg border hover:bg-gray-50"
              >
                <ArrowBack className="!w-3 !h-3" />
              </button>
              {children}
            </div>
          </main>
          <HelpBanner />
        </div>
        <aside className="w-96 shrink-0">
          <ContractStepsNav />
        </aside>
      </div>
    </div>
  );
};
