import { ApiIndividualResponse, ApiPaginatedResponse } from '@/types/api';
import {
  IDocument,
  IDocumentCreation,
  IDocumentFilter,
  IDocumentWithTemporaryUrl,
  IDocumentWithUploadTo,
} from '@/types/document';
import { PaginationSearchParams } from '@/types/pagination';
import { SortingSearchParams } from '@/types/sorting';
import qs from 'query-string';
import { ApiService } from './api';

export class DocumentsService extends ApiService {
  static PATH = '/documents';

  static async createDocument(
    document: IDocumentCreation,
  ): Promise<ApiIndividualResponse<IDocumentWithUploadTo>> {
    return ApiService.post(DocumentsService.PATH, document);
  }

  static async updateAndUploadDocument(
    documentId: string,
    file: File,
  ): Promise<ApiIndividualResponse<IDocumentWithUploadTo>> {
    return ApiService.put(`${DocumentsService.PATH}/${documentId}`, file);
  }

  static async getDocument(
    documentId: string,
  ): Promise<ApiIndividualResponse<IDocumentWithTemporaryUrl>> {
    return ApiService.get(`${DocumentsService.PATH}/${documentId}`);
  }

  static async getDocuments(
    filters: IDocumentFilter,
    pagination?: PaginationSearchParams,
    sorting?: SortingSearchParams<IDocument>,
  ): Promise<ApiPaginatedResponse<IDocument>> {
    const queryString = qs.stringify({
      ...filters,
      ...pagination,
      ...(sorting && {
        sort: Object.entries(sorting).reduce<string[]>(
          (acc, [fieldName, sortValue]) => {
            return [...acc, `${fieldName}[${sortValue}]`];
          },
          [],
        ),
      }),
    });

    return ApiService.get(`${DocumentsService.PATH}?${queryString}`);
  }
}
