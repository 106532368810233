import { ContractInfoBanner } from '@/modules/onboarding/components/ContractInfoBanner';
import { StripeBanner } from '@/modules/onboarding/components/StripeBanner';

export function EducationProviderSettingsPage() {
  return (
    <div className="flex flex-col h-full text-mydra-black">
      <header className="flex justify-between items-center px-12 pt-20">
        <h1 className="text-2xl font-medium">Organization settings</h1>
      </header>
      <main className="flex flex-col gap-4 px-12 py-12 @container">
        <StripeBanner />
        <ContractInfoBanner />
      </main>
    </div>
  );
}
