import { ApiIndividualResponse } from '@/types/api';
import {
  ApiIndividualContract,
  ApiPaginatedContracts,
  IContract,
  IContractGetFilters,
  IContractPostPayload,
  ICreateContractRecipientViewUrlResponse,
  PostContractResponse,
} from '@/types/contract';
import { PaginationSearchParams } from '@/types/pagination';
import { SortingSearchParams } from '@/types/sorting';
import qs from 'query-string';
import { ApiService } from './api';

export class ContractService extends ApiService {
  static GET_PATH = `/contracts`;
  static POST_PATH = `/contracts`;
  static RECIPIENT_VIEW_PATH = `/contracts/recipient-view`;
  static async getContracts(
    filters: IContractGetFilters = {},
    pagination: PaginationSearchParams = {},
    sorting: SortingSearchParams<IContract> = {},
  ): Promise<ApiPaginatedContracts> {
    const queryString = qs.stringify({
      ...filters,
      ...pagination,
      ...(sorting && {
        sort: Object.entries(sorting).reduce<string[]>(
          (acc, [fieldName, sortValue]) => {
            return [...acc, `${fieldName}[${sortValue}]`];
          },
          [],
        ),
      }),
    });

    return ApiService.get(`${ContractService.GET_PATH}?${queryString}`);
  }

  static async getContract(id: string): Promise<ApiIndividualContract> {
    return ApiService.get(`${ContractService.GET_PATH}/${id}`);
  }

  static async postContract(
    contract: IContractPostPayload,
    returnUrl?: string,
  ): Promise<PostContractResponse> {
    const queryParams = qs.stringify({ returnUrl });

    return ApiService.post(
      `${ContractService.POST_PATH}?${queryParams}`,
      contract,
    );
  }

  static async createContractRecipientViewUrl(
    contractId?: string,
    envelopeId?: string,
    returnUrl?: string,
  ): Promise<ApiIndividualResponse<ICreateContractRecipientViewUrlResponse>> {
    return ApiService.post(ContractService.RECIPIENT_VIEW_PATH, {
      contractId,
      envelopeId,
      returnUrl,
    });
  }
}
