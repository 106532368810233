import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { IUserWithEducationProvider } from '@/types/user';
import { useNavigate } from '@tanstack/react-router';

export const ContractExplanationStep1 = () => {
  const { isLoading: isLoadingUser, currentUser } =
    useCurrentUserContext() ?? {};

  const navigate = useNavigate();

  return isLoadingUser ? null : (
    <div className="flex flex-col gap-6">
      <p className="text-lg text-text">
        Please review this information carefully before signing.
      </p>

      <div className="flex flex-col gap-6 text-black">
        <h3 className="text-3xl font-semibold">Partnership Overview</h3>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Who We Are</h4>
          <p className="text-lg">
            This agreement is between StudentFinance (with headquarters in
            Madrid, Spain) and{' '}
            {
              (currentUser as IUserWithEducationProvider)?.educationProvider!
                .info?.legalName
            }
            .
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Purpose of the Contract</h4>
          <p className="text-lg">
            This partnership allows you to offer your educational programs on
            the Mydra platform, connecting you with students and clients who can
            choose from flexible payment options.
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Your Role</h4>
          <p className="text-lg">
            As an Education Provider, you're responsible for delivering course
            content, updating program details, and managing any student-related
            queries.
          </p>
        </div>
      </div>

      <div className="mt-12 ml-auto">
        <Button
          primary
          onClick={() => {
            navigate({
              search: {
                step: 2,
              },
            });
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
