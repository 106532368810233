import { USER_ROLES } from '@/constants';
import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Menu } from '@mui/base';
import {
  Apps,
  BusinessOutlined,
  PeopleOutlined,
  SchoolOutlined,
  SpaceDashboardOutlined,
  TrendingUp,
  WebOutlined,
} from '@mui/icons-material';
import { Link } from '@tanstack/react-router';
import React from 'react';
import { MenuItem } from '../MenuItem';

const ROUTES = [
  {
    icon: <SpaceDashboardOutlined />,
    label: 'Home',
    path: '/',
    roles: [],
  },
  {
    icon: <SchoolOutlined />,
    label: 'Courses',
    path: '/courses',
    roles: [USER_ROLES.EDUCATION_PROVIDER],
  },
  {
    icon: <Apps />,
    label: 'Applications',
    path: '/applications',
    roles: [
      USER_ROLES.EMPLOYER,
      USER_ROLES.TAX_BENEFITS_PROVIDER,
      USER_ROLES.EDUCATION_PROVIDER,
    ],
  },
  {
    icon: <TrendingUp />,
    label: 'Payments',
    path: '/payments',
    roles: [USER_ROLES.EMPLOYER, USER_ROLES.TAX_BENEFITS_PROVIDER],
  },
  {
    icon: <PeopleOutlined />,
    label: 'Employees',
    path: '/employees',
    roles: [USER_ROLES.EMPLOYER],
  },
  {
    icon: <BusinessOutlined />,
    label: 'Companies',
    path: '/companies',
    roles: [USER_ROLES.TAX_BENEFITS_PROVIDER],
  },
  {
    icon: <WebOutlined />,
    label: 'Landing Pages',
    path: '/landing-pages',
    roles: [USER_ROLES.EDUCATION_PROVIDER],
  },
];

type SideBarProps = React.HTMLAttributes<HTMLDivElement>;

export function SideBar(props: SideBarProps) {
  const userContext = useCurrentUserContext();

  return (
    <div
      {...props}
      className={`flex flex-col flex-1 justify-between border-r ${props.className ?? ''}`}
    >
      <Menu
        slotProps={{
          root: {
            className: '',
          },
          listbox: {
            className: 'flex flex-col self-stretch p-4 gap-2',
          },
        }}
      >
        {ROUTES.map(({ icon, label, path, roles }) => {
          if (
            !userContext?.currentUser.roles?.some(role => roles.includes(role))
          ) {
            return null;
          }

          return (
            <MenuItem icon={icon} className="text-base" key={path}>
              <Link to={path}>{label}</Link>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
