import { USER_ROLES } from '@/constants';

import { CurrentUserContextProvider } from '@/modules/auth/providers/AuthUserProvider';
import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { UseEmployerQueryOptions } from '@/modules/common/hooks/useEmployer';
import { getUseCurrentUserOptions } from '@/modules/common/hooks/useUser';
import { ApiError } from '@/services/api';
import { ICompany } from '@/types/company';
import { UserInclude } from '@/types/user';
import { Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_admin')({
  beforeLoad: async ({ context: { queryClient } }) => {
    const { data: user } = await queryClient.ensureQueryData(
      getUseCurrentUserOptions([
        UserInclude.EducationProvider,
        UserInclude.Company,
        UserInclude.TaxBenefitProviders,
      ]),
    );

    if (
      ![
        USER_ROLES.EDUCATION_PROVIDER,
        USER_ROLES.EMPLOYER,
        USER_ROLES.TAX_BENEFITS_PROVIDER,
      ].some(role => user?.roles?.includes(role))
    ) {
      throw new ApiError('Access denied', 403);
    }

    if (user?.roles.includes(USER_ROLES.EMPLOYER)) {
      try {
        await queryClient.ensureQueryData(
          UseEmployerQueryOptions((user.company as ICompany)?._id as string),
        );
      } catch {
        throw new ApiError('Access denied', 403);
      }
    }

    return {
      user,
    };
  },
  component: () => (
    <CurrentUserContextProvider>
      <Outlet />
    </CurrentUserContextProvider>
  ),
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 500 }} reset={() => null} />;
  },
});
