import { IAmount } from './amount';
import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { ICourse, ICourseWithEducationProvider } from './course';
import { IOrder } from './order';
import { IUser, IUserWithProfile } from './user';

export type ApiIndividualApplication = ApiIndividualResponse<IApplication>;
export type ApiPaginatedApplications = ApiPaginatedResponse<IApplication>;
export type ApiPaginatedApplicationsWithOrder =
  ApiPaginatedResponse<IApplicationWithOrderAndCourse>;

export enum ApplicationStatus {
  Applied = 'APPLIED',
  PendingContract = 'PENDING_CONTRACT',
  ContractSigned = 'CONTRACT_SIGNED',
  MissingScoringData = 'MISSING_SCORING_DATA',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  PendingValidation = 'PENDING_VALIDATION',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED',
}

export enum ApplicationTerm {
  Upfront = 'UPFRONT',
  FixPay = 'FIXPAY',
  Isa = 'ISA',
  Elsa = 'ELSA',
  Free = 'FREE',
  NoAgreement = 'NO_AGREEMENT',
}

export enum ApplicationValidationReviewer {
  Internal = 'internal',
  Employer = 'employer',
  EducationProvider = 'education-provider',
  FlexibleRemunerationProvider = 'flexible-remuneration-provider',
}

export enum ApplicationValidationStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum ApplicationValidationType {
  Scoring = 'scoring',
  LearningBudget = 'learning-budget',
  FlexibleRemuneration = 'flexible-remuneration',
  Skills = 'skills',
  Capacity = 'capacity',
  AdmissionProcess = 'admission-process',
}

export interface IContractChoices {
  fixedNumberOfInstalments: number;
  courseStartDate: string;
  courseLocation: string;
  preferredPaymentDay: number;
}
export interface IApplicationData {
  contractChoices?: IContractChoices;
}

export interface IApplicationSteps {
  shouldEducationProviderValidate: boolean;
  shouldEmployerValidate: boolean;
  shouldFlexibleRemunerationValidate: boolean;
}

export interface IApplicationValidationReview {
  reviewer: ApplicationValidationReviewer;
  status: ApplicationValidationStatus;
  auto?: boolean;
  reviewedAt?: Date;
  comments?: string;
}

export interface IApplicationValidation {
  name: ApplicationValidationType;
  reviews: IApplicationValidationReview[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IApplication {
  _id?: string;
  user: string | IUser;
  course: string | ICourse;
  cohort: string;
  flexibleRemuneration: boolean;
  applicationTerm: ApplicationTerm;
  statuses?: ApplicationStatus[];
  applicationData?: IApplicationData;
  applicationSteps?: IApplicationSteps;
  learningBudget?: IAmount;
  checkoutUrl?: string;
  validations?: IApplicationValidation[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IApplicationWithCourse
  extends Omit<IApplication, 'user' | 'course'> {
  course: ICourseWithEducationProvider;
  user: IUserWithProfile;
}

export interface IApplicationWithOrderAndCourse extends IApplicationWithCourse {
  order: IOrder;
}

export interface IApplicationPayload extends Omit<IApplication, 'statuses'> {
  status: ApplicationStatus;
}
