import { DocumentsService } from '@/services/document';
import { ApiErrorResponse, ApiIndividualResponse } from '@/types/api';
import { IDocumentWithTemporaryUrl } from '@/types/document';
import { UseQueryOptions } from '@tanstack/react-query';

export const UseDocumentQueryOptions = (
  documentId: string,
  options?: Omit<
    UseQueryOptions<
      ApiIndividualResponse<IDocumentWithTemporaryUrl>,
      ApiErrorResponse
    >,
    'queryKey' | 'queryFn'
  >,
) => ({
  queryKey: [DocumentsService.PATH, documentId],
  queryFn: async () => await DocumentsService.getDocument(documentId),
  ...options,
});
