import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { IDocumentReference } from './document-reference';
import { IUser } from './user';

export enum ContractKind {
  CustomerFinancingContract = 'customer-financing-contract',
  EducationProviderEPFinancingContract = 'education-provider-ep-financing-contract',
}

export enum CONTRACT_PROVIDER {
  DOCUSIGN = 'DocuSign',
  E_SIGNATURES_IO = 'eSignaturesIo',
}

export interface IContract {
  _id: string;
  provider: CONTRACT_PROVIDER;
  title: string;
  envelopeId: string;
  targetEntity: IDocumentReference;
  envelopeData: Record<string, unknown>;
  status: IContractStatus;
  contractKind: ContractKind;
  recipientsInfo: IRecipientInfo[];
  /** The date when all recipients signed the contract */
  dateSigned?: Date;
  /** The date when the contract was sent to the customer */
  dateVoided?: Date;
  dateDeclined?: Date;
  createdAt: Date;
  updatedAt: Date;
  user: string | IUser;
}

export enum IContractStatus {
  Created = 'CREATED',
  AwaitingSignature = 'AWAITING_SIGNATURE',
  Signed = 'SIGNED',
  Declined = 'DECLINED',
  Voided = 'VOIDED',
}

export enum IContractIncludes {
  Application = 'application',
  User = 'user',
}

export interface IRecipientInfo {
  recipientId: string;
  recipientRole: string;
  email: string;
  userId?: string;
  userName: string;
  status: IRecipientStatus;
  createdAt: Date;
  updatedAt: Date;
}

export enum IRecipientStatus {
  Created = 'CREATED',
  AutoResponded = 'AUTO_RESPONDED',
  Delivered = 'DELIVERED',
  Sent = 'SENT',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  AuthenticationFailed = 'AUTHENTICATION_FAILED',
  Resent = 'RESENT',
  Delegate = 'DELEGATE',
  Reassign = 'REASSIGN',
  FinishLater = 'FINISH_LATER',
}

export interface IContractWithUser extends IContract {
  user: IUser;
}

export type ApiIndividualContract = ApiIndividualResponse<IContract>;
export type ApiPaginatedContracts = ApiPaginatedResponse<IContract>;

export type IContractPostPayload = {
  applicationId?: string;
  educationProviderId?: string;
};

export type ICreactContractRecipientViewUrlPayload = {
  contractId?: string;
  envelopeId?: string;
  returnUrl?: string;
};

export interface IContractGetFilters {
  status?: IContractStatus | IContractStatus[];
  applicationId?: string;
  educationProviderId?: string;
  contractKind?: ContractKind;
}

export type IContractCreationResponse = {
  contractId: string;
  signingUrl: string;
};
export type ICreateContractRecipientViewUrlResponse = {
  url: string;
};

export type PostContractResponse = ApiIndividualResponse<{
  contractId: string;
  signingUrl: string;
}>;

export const CONTRACT_FINAL_STATUSES = [
  IContractStatus.Signed,
  IContractStatus.Voided,
  IContractStatus.Declined,
];
