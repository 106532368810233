import { Button } from '@/modules/common/components/Button';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { useCurrentUser } from '@/modules/common/hooks/useUser';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import {
  CONTRACT_FINAL_STATUSES,
  ContractKind,
  IContractStatus,
  IRecipientStatus,
} from '@/types/contract';
import { IEducationProvider } from '@/types/education-provider';
import { UserInclude } from '@/types/user';
import { keepPreviousData } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { useContractMutation } from '../hooks/useContractMutation';
import { useContractRecipientViewMutation } from '../hooks/useContractRecipientViewMutation';
import { useContractSignatureTransientState } from '../hooks/useContractSignatureTransientState';
import { useContracts } from '../hooks/useContracts';
import { ContractInformationModal } from './ContractInformationModal';

const routeApi = getRouteApi('/_admin/contract/');

export const ContractExplanationStep5 = () => {
  const [signingUrl, setSigningUrl] = useState<string | null>(null);
  const { showNotification } = useNotificationContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const navigate = routeApi.useNavigate();

  const {
    contractInfo: transientContractInfo,
    isLoading: isLoadingTransientContractInfo,
    clearContractTransientState,
  } = useContractSignatureTransientState();

  const { data: { data: currentUser } = {}, isLoading: isLoadingCurrentUser } =
    useCurrentUser([UserInclude.EducationProvider]);

  const { mutateAsync: createContract, isPending: isCreatingContract } =
    useContractMutation();
  const {
    mutateAsync: createRecipientView,
    isPending: isCreatingRecipientView,
  } = useContractRecipientViewMutation();

  const { data: { data: [lastContract] = [] } = {} } = useContracts(
    {
      educationProviderId: (
        currentUser?.educationProvider as unknown as IEducationProvider
      )?._id,
      status: [
        IContractStatus.Signed,
        IContractStatus.AwaitingSignature,
        IContractStatus.Created,
        IContractStatus.Voided,
      ],
      contractKind: ContractKind.EducationProviderEPFinancingContract,
    },
    undefined,
    {
      updatedAt: 'desc',
    },
    {
      enabled: !!(
        currentUser?.educationProvider as unknown as IEducationProvider
      )?._id,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: true,
      refetchInterval: ({
        state: { data: { data: [contract] = [] } = {} } = {},
      }) => {
        const contractIsFinalized = CONTRACT_FINAL_STATUSES.includes(
          contract?.status,
        );

        if (isIframeLoaded && !contractIsFinalized) {
          return 5000;
        }

        return false;
      },
    },
  );

  const isLoading =
    isLoadingTransientContractInfo ||
    isCreatingContract ||
    isCreatingRecipientView ||
    isLoadingCurrentUser;

  const lastContractWasDeclinedByUser = lastContract?.recipientsInfo.some(
    recipient =>
      recipient.email === currentUser?.email &&
      recipient.status === IRecipientStatus.Declined,
  );

  const lastContractIsSigned = lastContract?.status === IContractStatus.Signed;

  const lastContractIsDeclined =
    lastContract?.status === IContractStatus.Declined ||
    lastContract?.status === IContractStatus.Voided || // declined contracts become voided at the end of the process
    lastContractWasDeclinedByUser;

  const lastContractIsFinalized = CONTRACT_FINAL_STATUSES.includes(
    lastContract?.status,
  );

  const onContractCreation = async () => {
    try {
      if (
        !currentUser ||
        !(currentUser?.educationProvider as unknown as IEducationProvider)?._id
      ) {
        throw new Error('Invalid data');
      }

      let contractViewOrSignUrl: string | null = null;

      if (lastContract || transientContractInfo?.envelopeId) {
        const contractRecipientViewResponse = await createRecipientView({
          ...(transientContractInfo?.envelopeId
            ? {
                envelopeId: transientContractInfo.envelopeId,
              }
            : {
                contractId: lastContract._id,
              }),
        });

        contractViewOrSignUrl = contractRecipientViewResponse.data.url;
      } else {
        const contractSignUrlResponse = await createContract({
          educationProviderId: (
            currentUser.educationProvider as unknown as IEducationProvider
          )._id,
        });

        contractViewOrSignUrl = contractSignUrlResponse.data.signingUrl;
      }

      if (!contractViewOrSignUrl) {
        throw new Error(
          'Failed to generate the contract, please try again later',
        );
      }

      setSigningUrl(contractViewOrSignUrl);
    } catch {
      setSigningUrl(null);
      showNotification(
        'Failed to generate the contract, please try again later',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleNavigation = () => {
    setIsIframeLoaded(true);
  };

  const onFinish = () => {
    navigate({
      to: '/',
    });
  };

  const handleContractInfoClose = async () => {
    await navigate({
      to: '/',
    });
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    if (!signingUrl || !iframe) {
      return;
    }

    iframe.addEventListener('onLoad', handleNavigation);
    return () => {
      iframe.removeEventListener('onLoad', handleNavigation);
    };
  }, [signingUrl]);

  const shouldOpenContractInfoModal =
    !isLoading &&
    (!!transientContractInfo?.envelopeId ||
      lastContractIsDeclined ||
      lastContractIsSigned);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (lastContractIsFinalized) {
      clearContractTransientState();
    }
  }, [lastContractIsFinalized, clearContractTransientState, isLoading]);

  return (
    <>
      <div className="flex flex-col gap-6">
        <p className="text-lg text-text">
          Please review this information carefully before signing.
        </p>

        <div className="flex flex-col gap-6 text-black">
          <h3 className="text-3xl font-semibold">
            Confirm your partnership with your signature
          </h3>

          <div className="flex flex-col">
            <h4 className="text-lg font-semibold">Data Protection Agreement</h4>
            <p className="text-lg">
              This agreement requires both parties to comply with GDPR, treating
              shared Personal Data with confidentiality and each acting as a
              Data Controller. If roles change to include data processing, the
              parties will establish a data processing agreement.
            </p>
          </div>

          <div className="flex flex-col">
            <h4 className="text-lg font-semibold">Data Security Measures</h4>
            <p className="text-lg">
              The recipient of Personal Data must protect it with stringent
              technical measures, prevent unauthorized transfers, and promptly
              address any data breaches.
            </p>
          </div>

          <div className="flex flex-col">
            <h4 className="text-lg font-semibold">Compliance Priority</h4>
            <p className="text-lg">
              Both parties will work together to resolve any data security
              issues, and this data protection clause takes precedence over
              conflicting terms in the contract.
            </p>
          </div>
        </div>

        <div className="mt-6 relative overflow-hidden border rounded-lg min-h-[436px] w-full flex items-center justify-center">
          {lastContractIsDeclined ? (
            <div className="flex absolute inset-0 justify-center items-center bg-mydra-medium-gray">
              <p className="text-lg">
                {lastContractWasDeclinedByUser &&
                (
                  currentUser?.educationProvider as unknown as IEducationProvider
                )?.name
                  ? `You declined the contract.`
                  : 'The contract was declined.'}
              </p>
            </div>
          ) : signingUrl ? (
            <iframe
              title="Contract Document"
              src={`${signingUrl}?embedded=yes`}
              width="688"
              height="436"
              className="w-full border-0"
              onLoad={handleNavigation}
              ref={iframeRef}
            />
          ) : (
            <div className="flex justify-center items-center h-full">
              <Button
                primary
                disabled={isLoadingCurrentUser}
                onClick={onContractCreation}
                isLoading={isCreatingContract || isCreatingRecipientView}
              >
                Click here to{' '}
                {transientContractInfo?.envelopeId || lastContractIsFinalized
                  ? 'view'
                  : 'sign'}{' '}
                the contract
              </Button>
            </div>
          )}
        </div>

        <div className="flex gap-4 items-center mt-12 ml-auto">
          <Button
            disabled={
              isLoading ||
              !(
                transientContractInfo?.envelopeId ||
                lastContractWasDeclinedByUser ||
                lastContractIsFinalized
              )
            }
            primary
            onClick={onFinish}
          >
            {transientContractInfo?.envelopeId ? 'Finish' : 'Close'}
          </Button>
        </div>
      </div>
      <ContractInformationModal
        open={!!shouldOpenContractInfoModal}
        onClose={handleContractInfoClose}
        isSigned={!!transientContractInfo?.envelopeId || lastContractIsSigned}
      />
    </>
  );
};
