import { ContractService } from '@/services/contracts';
import { ApiErrorResponse, ApiIndividualResponse } from '@/types/api';
import {
  ICreactContractRecipientViewUrlPayload,
  ICreateContractRecipientViewUrlResponse,
} from '@/types/contract';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export function useContractRecipientViewMutation(
  options: Omit<
    UseMutationOptions<
      ApiIndividualResponse<ICreateContractRecipientViewUrlResponse>,
      ApiErrorResponse,
      ICreactContractRecipientViewUrlPayload
    >,
    'mutationKey' | 'mutationFn'
  > = {},
) {
  return useMutation<
    ApiIndividualResponse<ICreateContractRecipientViewUrlResponse>,
    ApiErrorResponse,
    ICreactContractRecipientViewUrlPayload
  >({
    ...options,
    mutationKey: [ContractService.RECIPIENT_VIEW_PATH],
    mutationFn: ({
      contractId,
      envelopeId,
      returnUrl,
    }: ICreactContractRecipientViewUrlPayload) => {
      return ContractService.createContractRecipientViewUrl(
        contractId,
        envelopeId,
        returnUrl,
      );
    },
  });
}
