import { ApiErrorResponse } from '@/types/api';
import {
  ApiPaginatedDocuments,
  IDocument,
  IDocumentFilter,
} from '@/types/document';
import { PaginationSearchParams } from '@/types/pagination';
import { SortingSearchParams } from '@/types/sorting';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UseDocumentsQueryOptions } from './useDocumentsQueryOptions';

export const useDocuments = (
  filters: IDocumentFilter,
  pagination?: PaginationSearchParams,
  sorting?: SortingSearchParams<IDocument>,
  options?: Omit<
    UseQueryOptions<ApiPaginatedDocuments, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<ApiPaginatedDocuments, ApiErrorResponse>(
    UseDocumentsQueryOptions(filters, pagination, sorting, options),
  );
