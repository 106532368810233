import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { useContractSignatureTransientState } from '../hooks/useContractSignatureTransientState';

type SignTheContractBannerProps = React.HTMLAttributes<HTMLDivElement>;

export function SignTheContractBanner(props: SignTheContractBannerProps) {
  const {
    educationProvider,
    currentUser,
    isLoading: isLoadingUser,
  } = useCurrentUserContext() ?? {};
  const { onboardingState } = educationProvider ?? {};
  const navigate = useNavigate();

  const {
    contractInfo: transientContractInfo,
    isLoading: isLoadingTransientContractInfo,
  } = useContractSignatureTransientState();

  const userIsLegalRepresentativeContact = !!(
    currentUser?.email &&
    educationProvider?.info?.contractRepresentativeContact &&
    educationProvider?.info?.contacts?.find(
      contact =>
        contact._id ===
          educationProvider?.info?.contractRepresentativeContact &&
        contact.email === currentUser.email,
    )
  );

  const shouldSignAContract =
    onboardingState?.hasLegalInformation &&
    onboardingState?.hasLegalRepresentativeContact &&
    onboardingState?.hasActivePaymentProvider &&
    !onboardingState?.hasActiveAgreement &&
    userIsLegalRepresentativeContact &&
    !transientContractInfo?.envelopeId;

  const isLoading = isLoadingTransientContractInfo || isLoadingUser;

  const redirectToContractSignature = useCallback(() => {
    navigate({
      to: '/contract',
    });
  }, [navigate]);

  if (isLoading) {
    return null;
  }

  return shouldSignAContract ? (
    <div
      {...props}
      className={`flex flex-col @md:flex-row @md:items-center @md:justify-between gap-3 border rounded-xl px-3 py-6 @md:px-4 overflow-hidden bg-gray-background ${props.className ?? ''} `}
    >
      <div className="flex flex-col gap-3">
        <div className="flex gap-1 items-center">
          <h3 className="text-sm @md:text-base font-medium @md:font-semibold">
            You’re almost there, please sign your agreement!
          </h3>
        </div>
        <p className="text-xs @md:text-sm @md:font-medium">
          This document details the terms and conditions of your partnership
          agreement.
        </p>
      </div>
      <Button
        primary
        isLoading={isLoading}
        className="@md:ml-auto"
        disabled={isLoading}
        small
        onClick={redirectToContractSignature}
      >
        Sign agreement
      </Button>
    </div>
  ) : null;
}
