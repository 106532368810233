import { Button } from '@/modules/common/components/Button';
import { useNavigate } from '@tanstack/react-router';

export const ContractExplanationStep2 = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-6">
      <p className="text-lg text-text">
        Please review this information carefully before signing.
      </p>

      <div className="flex flex-col gap-6 text-black">
        <h3 className="text-3xl font-semibold">
          Financial Terms & Commissions
        </h3>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Your Earnings</h4>
          <p className="text-lg">
            You'll receive payments from students through Mydra, which include
            options like FixPay, Flexible Remuneration, and Corporate Learning
            Vouchers.
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Commissions</h4>
          <p className="text-lg">
            StudentFinance will receive a 20% commission (+ applicable VAT) on
            each course fee paid through Mydra.
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Invoicing & Payment Timing</h4>
          <p className="text-lg">
            Payment for services occurs within 1 business day of a student's
            payment, with a 14-day processing window. If issues arise (e.g.,
            access delays for self-paced courses), payments may be withheld
            until resolved.
          </p>
        </div>
      </div>

      <div className="mt-12 ml-auto">
        <Button
          primary
          onClick={() => {
            navigate({
              search: {
                step: 3,
              },
            });
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
