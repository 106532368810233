import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  formatReviewCalls,
  getListApplicationsTableColumns,
} from './ListApplicationsTable.constants';

import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import {
  ApplicationValidationType,
  IApplicationWithOrderAndCourse,
} from '@/types/application';
import { UserRole } from '@/types/user';
import {
  CancelPresentation,
  Close,
  ReviewsOutlined,
} from '@mui/icons-material';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useReviewApplicationMutation } from '../../hooks/useApplications';
import { ReviewModal } from './components/ReviewModal/ReviewModal';
import { ReviewModalState } from './components/ReviewModal/ReviewModal.types';
import { TableFooter } from './components/TableFooter';

type ListApplicationsTable = {
  applications: IApplicationWithOrderAndCourse[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
} & React.TableHTMLAttributes<HTMLTableElement>;

export function ListApplicationsTable({
  applications,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  ...props
}: ListApplicationsTable) {
  const { currentUser: { roles: userRoles = [] } = {} } =
    useCurrentUserContext() ?? {};
  const { mutateAsync: reviewApplication } =
    useReviewApplicationMutation(undefined);
  const { showNotification } = useNotificationContext();

  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [applicationsToReview, setApplicationsToReview] = useState<
    IApplicationWithOrderAndCourse[]
  >([]);
  const columns = getListApplicationsTableColumns({
    onApplicationReview: (application: IApplicationWithOrderAndCourse) => {
      setApplicationsToReview([...applicationsToReview, application]);
      setReviewModalOpen(true);
    },
    userRoles,
  });

  const table = useReactTable<IApplicationWithOrderAndCourse>({
    data: applications,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel<IApplicationWithOrderAndCourse>(),
  });

  const rows = table.getRowModel().rows;

  const hasNoApplications = rows.length === 0 && !isLoading;

  const reviewSelectedApplications = async (
    reviews: Partial<ReviewModalState>,
  ) => {
    if (!applicationsToReview.length) {
      return;
    }

    try {
      setReviewModalOpen(false);

      const reviewCalls = formatReviewCalls(
        applicationsToReview,
        reviews,
        userRoles as UserRole[],
      );

      // @ts-expect-error TODO: fix this
      await Promise.all(reviewCalls.map(call => reviewApplication(call)));

      showNotification(
        `Application${applicationsToReview.length > 1 ? 's' : ''} reviewed successfully`,
        NotificationStyle.SUCCESS,
      );

      table.resetRowSelection();
    } catch {
      showNotification(
        `Failed to review application${applicationsToReview.length > 1 ? 's' : ''}`,
        NotificationStyle.ERROR,
      );
    }
  };

  const handleReviewMultiple = async () => {
    const selectedRowModel = table.getSelectedRowModel();

    if (selectedRowModel.rows.length === 0) {
      return;
    }

    setApplicationsToReview(selectedRowModel.rows.map(row => row.original));
    setReviewModalOpen(true);
  };

  const handleReviewSubmit = async (
    submittedReview?: Partial<ReviewModalState>,
  ) => {
    setReviewModalOpen(false);
    if (submittedReview) {
      await reviewSelectedApplications(submittedReview as ReviewModalState);
    } else {
      table.resetRowSelection();
    }
  };

  return (
    <>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoApplications ? 'h-full' : ''}`}
      >
        <thead>
          {table.getIsSomeRowsSelected() && (
            <tr>
              <th
                colSpan={table.getVisibleFlatColumns().length}
                className="p-0"
              >
                <div className="flex items-center w-full gap-3 p-4 text-xs font-medium rounded-md bg-gray-background">
                  <p>
                    {pluralize(
                      'item',
                      table.getSelectedRowModel().rows.length,
                      true,
                    )}{' '}
                    selected
                  </p>
                  <button
                    className="flex items-center p-1 ml-10 rounded-md text-mydra-purple hover:bg-mydra-medium-gray hover:text-black"
                    onClick={() => table.resetRowSelection()}
                  >
                    <Close className="w-6 h-6" />
                    Deselect
                  </button>

                  <div className="flex content-end w-full">
                    <button
                      className="flex items-center gap-2 p-1 ml-10 rounded-md text-text hover:bg-mydra-medium-gray hover:text-black"
                      onClick={() => {
                        setReviewModalOpen(true);
                        handleReviewMultiple();
                      }}
                    >
                      <ReviewsOutlined className="w-6 h-6" />
                      Review
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          )}
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-3 first:px-0 last:px-0"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoApplications ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="h-full p-0 text-center"
              >
                <div className="flex flex-col justify-center h-full p-3 align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className="text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">{`No applications found`}</p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.original._id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
      <ReviewModal
        applicationsToReview={applicationsToReview}
        userRoles={userRoles as UserRole[]}
        onClose={handleReviewSubmit}
        open={isReviewModalOpen}
        initialReview={{
          [ApplicationValidationType.Scoring]: false,
          [ApplicationValidationType.LearningBudget]: false,
          [ApplicationValidationType.FlexibleRemuneration]: false,
          [ApplicationValidationType.Skills]: false,
          [ApplicationValidationType.Capacity]: false,
          [ApplicationValidationType.AdmissionProcess]: false,
        }}
      />
    </>
  );
}
