import { Button } from '@/modules/common/components/Button';
import { Modal, ModalProps } from '@/modules/common/components/Modal';
import { useState } from 'react';

export type ContractInformationModalProps = Omit<
  ModalProps,
  'title' | 'children' | 'onClose'
> & {
  onClose: () => Promise<void>;
  isSigned?: boolean;
};

export function ContractInformationModal({
  onClose,
  isSigned,
  ...props
}: ContractInformationModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = async () => {
    setIsLoading(true);
    try {
      await onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="max-w-2xl"
      title={
        isSigned
          ? 'Thank you for signing the agreement! Here’s What’s Next:'
          : 'Your Agreement was Declined – Here’s What Happens Next:'
      }
      onClose={handleClose}
      {...props}
    >
      <div className="flex flex-col gap-6">
        <p className="text-lg">
          {isSigned ? (
            <>
              Please hold on as we finalize your confirmation. You’ll receive an
              email once everything is complete, and the signed agreement will
              be accessible in your dashboard
            </>
          ) : (
            <>
              <p className="font-bold">
                We're sorry, but your contract has been declined.
              </p>
              <p>
                Our Mydra Ops Team has been notified and will reach out to you
                shortly to provide further assistance and clarify the next
                steps. Thank you for your patience and understanding.
              </p>
            </>
          )}
        </p>
        <div className="flex justify-end">
          <Button primary onClick={handleClose} isLoading={isLoading}>
            OK, continue
          </Button>
        </div>
      </div>
    </Modal>
  );
}
