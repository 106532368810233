import { ApiPaginatedResponse } from './api';
import { IDocumentReference } from './document-reference';

export interface IDocument {
  _id: string;
  type: DocumentType;
  description?: string;
  s3Key: string;
  relationWith: IDocumentReference;
  owner: IDocumentReference;
  createdAt: string;
  updatedAt: string;
}

export interface IDocumentCreation
  extends Omit<IDocument, '_id' | 's3Key' | 'relationWith'> {
  s3Key?: string;
  relationWith?: IDocumentReference;
}

export interface IDocumentFilter {
  ownerId?: string;
  type?: DocumentType;
}

export type ApiPaginatedDocuments = ApiPaginatedResponse<IDocument>;

export interface IDocumentWithUploadTo extends IDocument {
  uploadTo: string;
}

export interface IDocumentWithTemporaryUrl extends IDocument {
  temporaryUrl: string;
}

export enum DocumentType {
  Contract = 'contract',
  IdBack = 'id_back',
  IdFront = 'id_front',
  OtherId = 'other_id',
  Passport = 'passport',
  Payslip = 'payslip',
  ProofOfAddress = 'proof_of_address',
  PortugueseCreditResponsibilityMap = 'portuguese_credit_responsibility_map',
  ExpensesBill = 'expenses_bill',
  BankStatement = 'bank_statement',
}
