import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { Switch } from '@/modules/common/components/Switch';
import { ApplicationValidationType } from '@/types/application';
import { useState } from 'react';
import { ReviewModalProps, ReviewModalState } from './ReviewModal.types';

type PaymentFeaturesReviewModalProps = ReviewModalProps & {
  validationsToReview: ApplicationValidationType[];
};

export function PaymentFeaturesReviewModal({
  applicationsToReview,
  validationsToReview,
  initialReview,
  onClose,
  ...props
}: PaymentFeaturesReviewModalProps) {
  const [review, setReview] = useState<ReviewModalState>(initialReview);

  return (
    <Modal
      {...props}
      title={`Please review ${applicationsToReview.length > 1 ? 'these application' : 'this application'}`}
      showCloseButton
      className="max-w-2xl"
    >
      <div className="flex flex-col gap-4">
        <p>
          Review and approve the following requests to ensure all payment
          features meet our standards for a seamless transaction experience.
        </p>
        <div>
          {validationsToReview.map(validation => (
            <div key={validation} className="flex flex-row items-center gap-2">
              <p>
                Approve{' '}
                {validation === ApplicationValidationType.FlexibleRemuneration
                  ? ('tax benefits' as const)
                  : ('learning budget' as const)}
              </p>
              <Switch
                slotProps={{
                  input: {
                    id: validation,
                  },
                }}
                onChange={event => {
                  setReview({
                    ...review,
                    [event.target.id as ApplicationValidationType]:
                      event.target.checked,
                  });
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end gap-3">
          <Button
            primary
            onClick={() => {
              onClose(review);
            }}
          >
            Submit
          </Button>
          <Button onClick={() => onClose(undefined)}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
}
