import { AuthService } from '@/services/auth';
import {
  ApiErrorResponse,
  ApiIndividualResponse,
  ApiReactMutationOptions,
} from '@/types/api';
import {
  AuthConfirmResetPayload,
  AuthLoginPayload,
  AuthResetPayload,
  AuthSession,
} from '@/types/auth';
import { useMutation } from '@tanstack/react-query';

export const useUserLogin = (
  options?: ApiReactMutationOptions<
    ApiIndividualResponse<AuthSession>,
    AuthLoginPayload
  >,
) =>
  useMutation<
    ApiIndividualResponse<AuthSession>,
    ApiErrorResponse,
    AuthLoginPayload
  >({
    mutationKey: [`${AuthService.PATH}/login`],
    mutationFn: AuthService.loginUser,
    ...options,
  });

export const useUserLogout = () =>
  useMutation<AuthSession, ApiErrorResponse, void>({
    mutationKey: [`${AuthService.PATH}/logout`],
    mutationFn: AuthService.logoutUser,
  });

export const useUserPasswordResetRequest = () =>
  useMutation<AuthSession, ApiErrorResponse, AuthResetPayload>({
    mutationKey: [`${AuthService.PATH}/reset-password`],
    mutationFn: AuthService.requestUserPasswordReset,
  });

export const useUserPasswordReset = () =>
  useMutation<AuthSession, ApiErrorResponse, AuthConfirmResetPayload>({
    mutationKey: [`${AuthService.PATH}/reset-password`],
    mutationFn: AuthService.resetUserPassword,
  });
