import { integerToMoney } from './moneyHandler';

export function formatDate(
  value: string,
  dateStyle:
    | 'long'
    | 'short'
    | 'medium'
    | 'full'
    | 'long-month-numeric-year'
    | '2-digit'
    | undefined,
) {
  if (!value || !dateStyle || Number.isNaN(Date.parse(value))) {
    return '';
  }

  if (dateStyle === '2-digit') {
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }).format(new Date(value));
  }

  if (dateStyle === 'long-month-numeric-year') {
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      year: 'numeric',
    }).format(new Date(value));
  }

  return new Intl.DateTimeFormat('en-US', { dateStyle }).format(
    new Date(value),
  );
}

const CURRENCY_REGEX = /^[A-Z]{3}$/i;

export function formatCurrencyValue(
  value: string,
  currency: string,
  formatOptions = {},
) {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: CURRENCY_REGEX.test(currency) ? currency : 'EUR',
    maximumFractionDigits: 0,
    ...formatOptions,
  }).format(integerToMoney(value));
}

export function formatSlug(slug: string) {
  return slug.replace(/_/g, ' ');
}

/**
 * example: transforms "learning_budget" into "Learning Budget"
 */
export function formatPaymentResource(resource: string) {
  return resource
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
