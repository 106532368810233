import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableFooter } from './ListOfCompaniesTable.components';
import { getListOfCompaniesTableColumns } from './ListOfCompaniesTable.constants';

import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { useTaxBenefitsProviderLinkMutation } from '@/modules/settings/hooks/useTaxBenefitProviderCompanyLink';
import { ICompany } from '@/types/company';
import { TaxBenefitsProviderCompanyLinkStatus } from '@/types/tax-benefits-provider';
import { CancelPresentation } from '@mui/icons-material';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { useReviewInviteModal } from './ListOfCompanies.hooks';

type ListOfCompaniesTableProps = {
  companies: ICompany[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
  onCompanyLinkUpdate: () => Promise<unknown>;
} & React.TableHTMLAttributes<HTMLTableElement>;

export function ListOfCompaniesTable({
  companies,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  onCompanyLinkUpdate,
  ...props
}: ListOfCompaniesTableProps) {
  const navigate = useNavigate();
  const { mutateAsync: companyLinkUpdate } = useTaxBenefitsProviderLinkMutation(
    undefined,
    {
      retry: false,
    },
  );
  const { showNotification } = useNotificationContext();

  const handleCompanyLinkUpdate = useCallback(
    async ({
      status,
      companyLinkId,
    }: {
      status: TaxBenefitsProviderCompanyLinkStatus | null;
      companyLinkId: string;
    }) => {
      try {
        await companyLinkUpdate({
          id: companyLinkId,
          data: {
            status: status!,
          },
        });
        showNotification(
          `Partnership updated successfully`,
          NotificationStyle.SUCCESS,
        );
        await onCompanyLinkUpdate();
      } catch {
        showNotification(
          `Failed to update partnership`,
          NotificationStyle.ERROR,
        );
      }
    },
    [companyLinkUpdate, showNotification, onCompanyLinkUpdate],
  );

  const { openReviewModal, Modal: ReviewInviteModal } = useReviewInviteModal(
    handleCompanyLinkUpdate,
  );

  const columns = getListOfCompaniesTableColumns({
    onInviteReview: (companyLinkId: string, companyName: string) => {
      openReviewModal(companyName, companyLinkId);
    },
    onNavigateToApplications: (companyId: string) => {
      navigate({
        to: '/applications',
        search: {
          companyId,
        },
      });
    },
  });

  const table = useReactTable<ICompany>({
    data: companies,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel<ICompany>(),
  });

  const rows = table.getRowModel().rows;

  const hasNoCompanies = rows.length === 0 && !isLoading;

  return (
    <>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoCompanies ? 'h-full' : ''}`}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-3 first:px-0 last:px-0"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoCompanies ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="p-0 h-full text-center"
              >
                <div className="flex flex-col justify-center p-3 h-full align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className="text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">{`No companies found`}</p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
      <ReviewInviteModal />
    </>
  );
}
