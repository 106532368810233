import { Tag } from '@/modules/common/components/Tag';
import { formatPaymentResource } from '@/modules/common/lib/formatters';
import { PaymentResource as PaymentResourceTypes } from '@/types/payment';

type PaymentResourceProps = {
  resource: PaymentResourceTypes;
};

const resourceBgColor: { [key in PaymentResourceTypes]: string } = {
  [PaymentResourceTypes.LearningBudget]: 'bg-mydra-purple-light',
  [PaymentResourceTypes.StudentRepayment]: 'bg-mydra-yellow',
  [PaymentResourceTypes.TaxBenefits]: 'bg-mydra-light-red',
};

export function PaymentResource({ resource }: PaymentResourceProps) {
  return (
    <Tag
      className={resourceBgColor[resource] ?? ''}
      text={formatPaymentResource(resource)}
    />
  );
}
