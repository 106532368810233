import { ApiErrorResponse } from '@/types/api';
import {
  ApiPaginatedContracts,
  IContract,
  IContractGetFilters,
} from '@/types/contract';
import { PaginationSearchParams } from '@/types/pagination';
import { SortingSearchParams } from '@/types/sorting';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UseContractsQueryOptions } from './useContractsQueryOptions';

export const useContracts = (
  filters: IContractGetFilters,
  pagination?: PaginationSearchParams,
  sorting?: SortingSearchParams<IContract>,
  options?: Omit<
    UseQueryOptions<ApiPaginatedContracts, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<ApiPaginatedContracts, ApiErrorResponse>(
    UseContractsQueryOptions(filters, pagination, sorting, options),
  );
