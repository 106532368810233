import { Button } from '@/modules/common/components/Button';
import { useNavigate } from '@tanstack/react-router';

export const ContractExplanationStep3 = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-6">
      <p className="text-lg text-text">
        Please review this information carefully before signing.
      </p>

      <div className="flex flex-col gap-6 text-black">
        <h3 className="text-3xl font-semibold">
          Program Information & Updates
        </h3>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">
            Program Information & Updates
          </h4>
          <p className="text-lg">
            Describe your programs, admission requirements, schedules, prices,
            and any payment options, promotions, or discounts.
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Dashboard for Updates</h4>
          <p className="text-lg">
            You can update these details directly on the Mydra platform, and
            changes should be communicated to StudentFinance at least 5 business
            days in advance.
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Admission Processes</h4>
          <p className="text-lg">
            If you require an admission process, confirm student acceptance
            within 5 business days. If you don't have an admission process, all
            candidates referred by StudentFinance are accepted automatically.
          </p>
        </div>
      </div>

      <div className="mt-12 ml-auto">
        <Button
          primary
          onClick={() => {
            navigate({
              search: {
                step: 4,
              },
            });
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
