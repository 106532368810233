import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { DropDown } from '@/modules/common/components/DropDown';
import { MenuItem } from '@/modules/common/components/MenuItem';
import {
  ApplicationStatus,
  ApplicationTerm,
  IApplicationWithOrderAndCourse,
} from '@/types/application';
import { IUserWithEducationProvider, UserRole } from '@/types/user';
import { List } from '@mui/icons-material';
import { keepPreviousData } from '@tanstack/react-query';
import { Link, getRouteApi } from '@tanstack/react-router';
import { PaginationState } from '@tanstack/react-table';
import { ListApplicationsTable } from '../components/ListApplicationsTable/ListApplicationsTable';
import { useGetApplications } from '../hooks/useApplications';
import { ApplicationsSearchParams } from '../types';

const routeApi = getRouteApi('/_admin/applications/');

const formatConstant = (constant: string) =>
  constant.replace(/_/g, ' ').toLocaleLowerCase();

const statusFilterOptions = Object.values(ApplicationStatus).map(status => ({
  label: formatConstant(status),
  value: status,
}));

const applicationTermFilterOptions = [
  {
    label: 'Free',
    value: ApplicationTerm.Free,
  },
  {
    label: 'Upfront',
    value: ApplicationTerm.Upfront,
  },
  {
    label: 'FixPay',
    value: ApplicationTerm.FixPay,
  },
  {
    label: 'ISA',
    value: ApplicationTerm.Isa,
  },
];

export function ListApplicationPage() {
  const {
    currentUser,
    taxBenefitsProvider,
    isLoading: isLoadingUser,
  } = useCurrentUserContext() ?? {};
  const search = routeApi.useSearch() as ApplicationsSearchParams;
  const navigate = routeApi.useNavigate();

  const {
    isLoading,
    data: {
      data: applications = [],
      metadata: { total = 0, page = 1 } = {},
    } = {},
  } = useGetApplications(
    {
      ...(currentUser?.roles.includes(UserRole.TaxBenefitsProvider) &&
        taxBenefitsProvider?._id && {
          taxBenefitsProviderId: taxBenefitsProvider._id,
          flexibleRemuneration: true,
        }),
      ...(currentUser?.roles.includes(UserRole.EducationProvider) &&
        currentUser.educationProvider && {
          educationProviderId: (currentUser as IUserWithEducationProvider)
            .educationProvider._id,
        }),
      ...(search.status && { status: search.status as ApplicationStatus }),
      ...(search.companyId && { companyId: search.companyId }),
      ...(search.applicationTerm && {
        applicationTerm: search.applicationTerm,
      }),
      ...(search.hasLearningBudget !== undefined && {
        hasLearningBudget: search.hasLearningBudget,
      }),
    },
    {
      page: search.page,
      limit: 10,
    },
    ['order'],
    {
      enabled: !isLoadingUser,
      placeholderData: keepPreviousData,
    },
  );

  const paginationState = {
    pageIndex: (page ?? 1) - 1 || 0,
    pageSize: 10,
  };

  const onPaginationChange = ({ pageIndex }: PaginationState) => {
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        page: pageIndex + 1,
      }),
    });
  };

  const showClearFilters =
    Object.entries(search).filter(
      ([key, value]) => key !== 'page' && key !== 'limit' && value,
    ).length > 0;

  const statusLabel = search.status ? formatConstant(search.status) : 'All';
  const applicationTermLabel = search.hasLearningBudget
    ? 'Learning budget'
    : search.applicationTerm
      ? formatConstant(search.applicationTerm)
      : 'All';

  return (
    <div className="flex flex-col h-full">
      <header className="flex items-center justify-between px-12 py-5 border-b">
        <h1 className="text-2xl font-medium">
          {currentUser?.roles.includes(UserRole.TaxBenefitsProvider)
            ? 'Tax benefits applications'
            : 'Applications'}
        </h1>
      </header>
      <div className="px-12 border-b">
        <div className="inline-flex items-center gap-2 py-5 text-base font-medium border-b-4 border-black">
          <List />
          Applications
        </div>
      </div>
      <div className="flex gap-12 px-12 py-6">
        <div className="flex items-center gap-12">
          <div className="flex items-center gap-1">
            <p className="text-xs font-medium">Type:</p>
            <DropDown
              toggleButtonLabel={applicationTermLabel}
              slotProps={{
                button: {
                  className: 'text-xs font-medium capitalize',
                },
              }}
            >
              <MenuItem
                key="all"
                className={`text-xs capitalize ${
                  search.status === undefined ? '!font-semibold' : ''
                }`}
                onClick={() => {
                  navigate({
                    search: prev => ({
                      ...prev,
                      applicationTerm: undefined,
                      hasLearningBudget: undefined,
                      page: 1,
                    }),
                  });
                }}
              >
                All
              </MenuItem>
              <MenuItem
                key="learning-budget"
                className={`text-xs capitalize border-t ${
                  search.hasLearningBudget === undefined ? '!font-semibold' : ''
                }`}
                onClick={() => {
                  navigate({
                    search: prev => ({
                      ...prev,
                      applicationTerm: undefined,
                      hasLearningBudget: true,
                      page: 1,
                    }),
                  });
                }}
              >
                Learning budget
              </MenuItem>
              {applicationTermFilterOptions.map(option => (
                <MenuItem
                  key={option.value}
                  className={`text-xs capitalize border-t last:border-b-0 ${
                    option.value === search.applicationTerm
                      ? '!font-semibold'
                      : ''
                  }`}
                  onClick={() => {
                    navigate({
                      search: prev => ({
                        ...prev,
                        applicationTerm: option.value,
                        hasLearningBudget: undefined,
                        page: 1,
                      }),
                    });
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </DropDown>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-xs font-medium">Status:</p>
            <DropDown
              toggleButtonLabel={statusLabel}
              slotProps={{
                button: {
                  className: 'text-xs font-medium capitalize',
                },
              }}
            >
              <MenuItem
                key="all"
                className={`text-xs capitalize ${
                  search.status === undefined ? '!font-semibold' : ''
                }`}
                onClick={() => {
                  navigate({
                    search: prev => ({
                      ...prev,
                      status: undefined,
                      page: 1,
                    }),
                  });
                }}
              >
                All
              </MenuItem>
              {statusFilterOptions.map(option => (
                <MenuItem
                  key={option.value}
                  className={`text-xs capitalize border-t last:border-b-0 ${
                    option.value === search.status ? '!font-semibold' : ''
                  }`}
                  onClick={() => {
                    navigate({
                      search: prev => ({
                        ...prev,
                        status: option.value,
                        page: 1,
                      }),
                    });
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </DropDown>
          </div>
        </div>
        {showClearFilters && (
          <div className="flex items-center">
            <Link
              className="text-xs font-medium text-mydra-purple"
              search={{
                page: 1,
              }}
            >
              Clear
            </Link>
          </div>
        )}
      </div>
      <div className="flex-1 px-12">
        <ListApplicationsTable
          isLoading={isLoading}
          onPaginationChange={updater => {
            return typeof updater === 'function'
              ? onPaginationChange(updater(paginationState))
              : onPaginationChange(updater);
          }}
          applications={applications as IApplicationWithOrderAndCourse[]}
          paginationState={paginationState}
          rowCount={total}
        />
      </div>
    </div>
  );
}
