import { ContractService } from '@/services/contracts';
import { ApiErrorResponse } from '@/types/api';
import {
  ApiPaginatedContracts,
  IContract,
  IContractGetFilters,
} from '@/types/contract';
import { PaginationSearchParams } from '@/types/pagination';
import { SortingSearchParams } from '@/types/sorting';
import { UseQueryOptions } from '@tanstack/react-query';

export const UseContractsQueryOptions = (
  filters: IContractGetFilters,
  pagination?: PaginationSearchParams,
  sorting?: SortingSearchParams<IContract>,
  options?: Omit<
    UseQueryOptions<ApiPaginatedContracts, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) => ({
  queryKey: [ContractService.GET_PATH, filters],
  queryFn: async () =>
    await ContractService.getContracts(filters, pagination, sorting),
  ...options,
});
