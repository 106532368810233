import { ContractSearchParams } from '@/routes/_admin/contract';
import { CheckOutlined } from '@mui/icons-material';
import { getRouteApi, Link } from '@tanstack/react-router';

import clsx from 'clsx';

const routeApi = getRouteApi('/_admin/contract/');

const contractSteps = [
  { id: 1, title: 'Partnership Overview' },
  { id: 2, title: 'Financial Terms & Commissions' },
  { id: 3, title: 'Program Information & Updates' },
  { id: 4, title: 'Compliance, Claims, and Termination' },
  { id: 5, title: 'Sign & Submit' },
];

type ContractStepsNavProps = React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
};

export const ContractStepsNav = ({
  disabled,
  ...props
}: ContractStepsNavProps) => {
  const { useSearch } = routeApi;
  const { step = 1 } = useSearch<ContractSearchParams>();

  const Component = disabled ? 'div' : Link;

  return (
    <div {...props} className={clsx('border p-6 rounded-lg', props.className)}>
      <ol className="relative flex flex-col gap-5">
        {contractSteps.map(item => {
          const isStepCompletedOrCurrent = step >= item.id;
          return (
            <li key={item.id}>
              <Component
                key={item.id}
                to={`/contract?step=${item.id}`}
                className={clsx('flex items-center relative z-10 gap-2')}
              >
                <div
                  className={clsx(
                    'h-6 my-auto aspect-square font-bold text-mydra-black justify-center rounded-full flex items-center text-sm',
                    isStepCompletedOrCurrent
                      ? 'bg-mydra-purple  text-white'
                      : 'bg-white border  border-mydra-black ',
                  )}
                >
                  {isStepCompletedOrCurrent ? (
                    <CheckOutlined className="text-white" />
                  ) : (
                    item.id
                  )}
                </div>
                <span
                  className={clsx(
                    'text-lg',
                    isStepCompletedOrCurrent ? 'font-bold' : '',
                  )}
                >
                  {item.title}
                </span>
              </Component>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
